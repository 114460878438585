import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Box } from "../../components/Core";
import PostCard from "../../components/PostCard";
import Pagination, { PageItem } from "../../components/Pagination";

import imgB1 from "../../assets/image/jpeg/blog-post--img-10.jpg";

const BlogList = ({ data }) => (
  <>
    {/* <!-- Blog section --> */}

    <Row className="align-items-center justify-content-center">
      {data &&
        data.allMarkdownRemark &&
        data.allMarkdownRemark.edges.map((post) => {
          const {
            title,
            author,
            date,
            image,
            description,
            path,
          } = post.node.frontmatter;

          return (
            <Col lg="6" className="mb-5">
              <PostCard
                img={image}
                preTitle={date}
                title={title}
                key={`${date}__${title}`}
                path={path}
                readMore
              >
                {description}
              </PostCard>
            </Col>
          );
        })}
    </Row>
    {false && (
      <Box className="d-flex justify-content-start" mt={4}>
        <Pagination>
          <PageItem>
            <FaAngleLeft />
          </PageItem>
          <PageItem>1</PageItem>
          <PageItem>2</PageItem>
          <PageItem>3</PageItem>
          <PageItem>...</PageItem>
          <PageItem>9</PageItem>
          <PageItem>
            <FaAngleRight />
          </PageItem>
        </Pagination>
      </Box>
    )}
  </>
);

export default BlogList;
