import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

import BlogList from "../sections/blog/BlogListSidebar";
import Sidebar from "../sections/blog/Sidebar";

export const AllBlogsQuery = graphql`
  query MyQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            date
            title
            category
            author
            path
            description
          }
        }
      }
    }
  }
`;

const BlogRightSidebar = ({ data }) => {
  return (
    <>
      <PageWrapper footerDark>
        <Section bg="lightShade">
          <Section className="pb-0">
            <div className="pt-5"></div>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <Title variant="hero">Petaler Blog</Title>
                  <Text>Goings on behind the scenes and out in the world.</Text>
                </Col>
              </Row>
            </Container>
          </Section>
          <Section>
            <Container>
              <Row>
                <Col lg="8" className="mb-5">
                  {<BlogList data={data} />}
                </Col>
              </Row>
            </Container>
          </Section>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogRightSidebar;
